import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

import { GetTaxDocumentsResponse } from "@zeal/common";

export const getCompanyTaxDocuments = (axios: IAxiosInstance) => {
  return async (
    company_id: string,
    params?: Record<string, string | number>
  ) => {
    const response = await axios.noRetry.get<{ data: GetTaxDocumentsResponse }>(
      `/api/${company_id}/documents/tax`,
      { params }
    );
    return response.data?.data;
  };
};
