import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TPostGenerateShareableLinkRequest } from "./postGenerateShareableLink.schema";

export const postGenerateShareableLink = (axios: IAxiosInstance) => {
  return async (body: TPostGenerateShareableLinkRequest) => {
    const response = await axios.noRetry.post<ZealApiResponse<string>>(
      "/api/postGenerateShareableLink",
      body
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
