import { z } from "zod";
import {
  JurisdictionNameMap,
  Jurisdiction,
} from "../../jurisdictions/Jurisdiction";
import { toCase } from "../../strings/toCase";

export const stateCode = () =>
  z
    .string()
    .trim()
    .transform((s) => {
      const normalized = s.toUpperCase();
      if (normalized.length === 2) return normalized;
      return JurisdictionNameMap[
        toCase(normalized, "title") as keyof typeof JurisdictionNameMap
      ];
    })
    .pipe(
      z.nativeEnum(Jurisdiction).refine((v) => v !== Jurisdiction.US, {
        message: "US is not a valid state.",
      })
    );
