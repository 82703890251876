import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetEmployeePaycardStatementRequestSchema,
  TGetEmployeePaycardStatementResponseSchema,
} from "./getEmployeePaycardStatement.schema";

export const getEmployeePaycardStatement = (axios: IAxiosInstance) => {
  return async (params: TGetEmployeePaycardStatementRequestSchema) => {
    const { employee_id, paycard_id, statement_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetEmployeePaycardStatementResponseSchema>
    >(
      `/api/employees/${employee_id}/paycards/${paycard_id}/statements/${statement_id}`,
      { params: rest }
    );

    return response.data;
  };
};
