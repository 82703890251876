import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const createEmployeeChecksFromBullhornCsv =
  (axios: IAxiosInstance) =>
  async ({ csv, checkDate }: { csv: string; checkDate: string }) => {
    const response = await axios.noRetry.post<{
      data: {
        job_id: string;
        status: string;
        created_at: string;
      };
    }>("/api/employee-checks/bullhorn-csv-jobs", {
      csv,
      check_date: checkDate,
    });
    return response.data.data;
  };
