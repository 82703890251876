import { schemes } from "@zeal/common";
import { z } from "zod";

// Named 'worker' instead of 'employee' on purpose since it's generic
export const getWorkerPaycardDisclosuresSchema = z.object({
  workerId: schemes.mongoObjectId(),
  document_keys: z
    .array(
      z.enum([
        "patriot_act",
        "deposit_agreement",
        "cardholder_agreement",
        "tisa_account_disclosure",
        "terms_and_conditions",
        "esign_disclosure",
      ])
    )
    .default([
      "patriot_act",
      "deposit_agreement",
      "cardholder_agreement",
      "tisa_account_disclosure",
      "terms_and_conditions",
      "esign_disclosure",
    ]),
});

export type TGetWorkerPaycardDisclosuresRequest = z.infer<
  typeof getWorkerPaycardDisclosuresSchema
>;

export type TGetWorkerPaycardDisclosuresResponse = Record<
  TGetWorkerPaycardDisclosuresRequest["document_keys"][number],
  string
>;
