import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  type TUpdateEmployeeRequest,
  type TUpdateEmployeeResponse,
} from "./updateEmployee.schema";

export const updateEmployee = (axios: IAxiosInstance) => {
  return async (data: TUpdateEmployeeRequest) => {
    const response = await axios.noRetry.patch<
      IApiResponse<TUpdateEmployeeResponse>
    >(
      "/employees",
      {
        companyID: data.companyID,
        employee: data.employee,
      },
      {
        headers: { "x-exchange-token": data.accessToken },
      }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
