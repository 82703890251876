import { schemes, zSchema } from "@zeal/common";

export const createBankAccountRequestSchema = zSchema.object({
  account_number: zSchema.string(),
  account_type: zSchema.string(),
  first_name: zSchema.string(),
  is_default: zSchema.boolean().optional(),
  last_name: zSchema.string(),
  odo_user_id: schemes.mongoOrUuid(),
  routing_number: zSchema.string(),
});

export type TCreateBankAccountRequestSchema = zSchema.infer<
  typeof createBankAccountRequestSchema
>;

export const createBankAccountResponseSchema = zSchema.object({
  odo_user_id: schemes.mongoOrUuid(),
  payment_destination_id: schemes.uuid(),
});

export type TCreateBankAccountResponseSchema = zSchema.infer<
  typeof createBankAccountResponseSchema
>;
