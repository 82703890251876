import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TestMode } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getMyEmployerChecks = (axios: IAxiosInstance) => {
  return async ({
    mode,
    currentYear,
    status,
  }: ISchema["#/definitions/IGetMyEmployerChecksQueryParams"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetMyEmployerChecksResponse"]
    >(`/api/getMyEmployerChecks`, {
      params: { test: mode === TestMode.Test, currentYear, status },
      withCredentials: true,
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
