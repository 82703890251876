import {
  TGetMyContractorPaymentsQuery,
  TGetMyContractorPaymentsResponseData,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TestMode } from "../../@types/general";

export const getMyContractorPayments = (axios: IAxiosInstance) => {
  return async ({
    mode,
    currentYear,
    status,
    q,
    limit,
    startAt,
  }: TGetMyContractorPaymentsQuery) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetMyContractorPaymentsResponseData>
    >("/api/getMyContractorPayments", {
      params: {
        test: mode === TestMode.Test,
        currentYear,
        status,
        q,
        start_at: startAt,
        limit,
      },
      withCredentials: true,
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
