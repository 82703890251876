/**
 * Truncates a string to a specified length and appends an ellipsis if necessary.
 *
 * @param {string} input - The string to be truncated.
 * @param {number} length - The maximum length of the truncated string including the ellipsis.
 * @param {string} [ellipsis="..."] - The string to append to the truncated string. Defaults to "...".
 * @returns {string} - The truncated string with the ellipsis appended if truncation occurred.
 *
 * @example
 * // returns "Hello..."
 * truncate("Hello, world!", 8);
 *
 * @example
 * // returns "Hello, world!"
 * truncate("Hello, world!", 20);
 *
 * @example
 * // returns "Hello***"
 * truncate("Hello, world!", 8, "***");
 */
export const truncate = (input: string, length: number, ellipsis = "...") => {
  if (input.length <= length) {
    return input;
  }

  return input.slice(0, length - ellipsis.length) + ellipsis;
};
