import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetContractorPaycardStatementsRequestSchema,
  TGetContractorPaycardStatementsResponseSchema,
} from "./getContractorPaycardStatements.schema";

export const getContractorPaycardStatements = (axios: IAxiosInstance) => {
  return async (params: TGetContractorPaycardStatementsRequestSchema) => {
    const { contractor_id, paycard_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetContractorPaycardStatementsResponseSchema>
    >(`/api/contractors/${contractor_id}/paycards/${paycard_id}/statements`, {
      params: rest,
    });

    return response.data;
  };
};
