import { accountStatement, schemes, zSchema } from "@zeal/common";

export const getPaycardStatementsRequestSchema = zSchema.object({
  date: schemes.isoDate().optional(),
  paycard_id: schemes.uuid(),
});

export type TGetPaycardStatementsRequestSchema = zSchema.infer<
  typeof getPaycardStatementsRequestSchema
>;

export const getPaycardStatementsResponseSchema =
  zSchema.array(accountStatement);

export type TGetPaycardStatementsResponseSchema = zSchema.infer<
  typeof getPaycardStatementsResponseSchema
>;
