import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetTransactionsRequestSchema,
  TGetTransactionsResponseSchema,
} from "./getTransactions.schema";

export const getTransactions = (axios: IAxiosInstance) => {
  return async (query: TGetTransactionsRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetTransactionsResponseSchema>
    >(`/api/transactions`, { params: query });

    return response.data;
  };
};
