import { z } from "zod";
import { schemes } from "../../schema-validation";

export const accountStatement = z.object({
  account_statement_id: schemes.uuid(),
  external_account_id: z.string(),
  external_file_id: z.string(),
  provider_account_id: schemes.uuid(),
  year_month: z.string(),
});
