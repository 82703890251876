import { Jurisdiction, schemes, zSchema } from "@zeal/common";

export const getEmployeeChecksRequestSchema = zSchema.object({
  check_ids: zSchema.array(schemes.mongoObjectId()).optional(),
  company_id: schemes.mongoObjectId(),
  cursor: schemes.mongoObjectId().optional(),
  employee_ids: zSchema.array(schemes.mongoObjectId()).optional(),
  end_date: schemes.isoDate().optional(),
  fields: zSchema.array(zSchema.enum(["id", "payDate"])).optional(),
  start_date: schemes.isoDate().optional(),
  include_migrated: zSchema.boolean().default(false),
});

export type TGetEmployeeChecksRequestSchema = zSchema.input<
  typeof getEmployeeChecksRequestSchema
>;

export const getEmployeeChecksResponseSchema = zSchema.array(
  zSchema.object({
    _id: schemes.mongoObjectId(),
    deductions: zSchema.array(
      zSchema.object({
        amount: zSchema.number(),
        deductionTypeCode: zSchema.string(),
        payer: zSchema.enum(["EE", "ER"]),
      })
    ),
    earnings: zSchema.array(
      zSchema.object({
        amount: zSchema.number(),
      })
    ),
    employeeId: schemes.mongoObjectId(),
    id: schemes.mongoObjectId(),
    payDate: zSchema.string(),
    residency: zSchema.object({
      city: zSchema.string(),
      state: zSchema.string(),
      street1: zSchema.string(),
      zip: zSchema.string(),
    }),
    withholdings: zSchema.array(
      zSchema.object({
        amount: zSchema.number(),
        jurisdiction: zSchema.nativeEnum(Jurisdiction),
        name: zSchema.string(),
        payer: zSchema.enum(["EE", "ER"]),
        taxTypeCode: zSchema.string(),
      })
    ),
  })
);

export type TGetEmployeeChecksResponseSchema = zSchema.infer<
  typeof getEmployeeChecksResponseSchema
>;
