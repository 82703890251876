// functions that determine a holiday based on the year
import dayjs from "dayjs";
import memoize from "lodash/memoize";
import { buildDate } from "../buildDate/buildDate";
import { lastDayOfMonth } from "../lastDayOfMonth/lastDayOfMonth";
import { nthDayOf } from "../nthDayOf/nthDayOf";

export const buildAllFederalBankingHolidays = (
  currentYear = new Date().getFullYear(),
  {
    shiftSaturdayHolidays = true,
    shiftSundayHolidays = true,
  }: { shiftSaturdayHolidays: boolean; shiftSundayHolidays: boolean }
) => {
  const holidays = [];
  // Include next year's holidays to avoid end-of-year inconsistencies
  const years = [currentYear, currentYear + 1];

  for (const year of years) {
    // New Year's Day
    holidays.push({
      name: `New Year's Day`,
      date: buildDate({ day: 1, month: 1, year }),
    });

    // Birthday of Martin Luther King, Jr.
    // Third Monday of January; fun fact: actual birthday is January 15
    holidays.push({
      name: `Birthday of Martin Luther King, Jr.`,
      date: nthDayOf(3, 1, 1, year),
    });

    // Washington's Birthday
    // Third Monday of February; fun fact: actual birthday is February 22
    // Fun fact 2: officially "Washington's Birthday," not "President's Day"
    holidays.push({
      name: `Washington's Birthday`,
      alsoObservedAs: "Presidents' Day",
      date: nthDayOf(3, 1, 2, year),
    });

    // TODO: District of Columbia Emancipation Day (April 15th/16th)

    // Memorial Day
    // Last Monday of May
    holidays.push({
      name: `Memorial Day`,
      date: lastDayOfMonth(1, 5, year),
    });

    if (year > 2020) {
      // Juneteenth
      holidays.push({
        name: `Juneteenth National Independence Day`,
        date: buildDate({ day: 19, month: 6, year }),
      });
    }

    // Independence Day
    holidays.push({
      name: `Independence Day`,
      date: buildDate({ day: 4, month: 7, year }),
    });

    // Labor Day
    // First Monday in September
    holidays.push({
      name: `Labor Day`,
      date: nthDayOf(1, 1, 9, year),
    });

    // Columbus Day
    // Second Monday in October
    holidays.push({
      name: `Columbus Day`,
      alsoObservedAs: "Indigenous Peoples' Day",
      date: nthDayOf(2, 1, 10, year),
    });

    // Veterans Day
    holidays.push({
      name: `Veterans Day`,
      date: buildDate({ day: 11, month: 11, year }),
    });

    // Thanksgiving Day
    // Fourth Thursday of November
    holidays.push({
      name: `Thanksgiving Day`,
      date: nthDayOf(4, 4, 11, year),
    });

    // Christmas Day
    holidays.push({
      name: `Christmas Day`,
      date: buildDate({ day: 25, month: 12, year }),
    });
  }

  return holidays.map((holiday) => {
    let date = dayjs(holiday.date);

    if (date.day() === 0 && shiftSundayHolidays) {
      // Actual holiday falls on Sunday. Shift the observed date forward to
      // Monday.
      date = date.add(1, "day");
    }

    if (date.day() === 6 && shiftSaturdayHolidays) {
      // Actual holiday falls on Saturday. Shift the observed date backward
      // to Friday.
      date = date.subtract(1, "day");
    }

    return {
      name: holiday.name,
      alsoObservedAs: holiday.alsoObservedAs,
      date: date.toDate(),
      dateString: date.format("YYYY-MM-DD"),
    };
  });
};

const federalBankingHolidayCache = memoize(
  buildAllFederalBankingHolidays,
  (
    year: number | undefined,
    {
      shiftSaturdayHolidays,
      shiftSundayHolidays,
    }: { shiftSaturdayHolidays: boolean; shiftSundayHolidays: boolean }
  ) => `${year} ${shiftSaturdayHolidays} ${shiftSundayHolidays}`
);

export function getFederalBankingHolidays(
  year: number = new Date().getFullYear()
) {
  return federalBankingHolidayCache(year, {
    shiftSaturdayHolidays: false,
    shiftSundayHolidays: true,
  });
}
