import { z } from "zod";

import { ContractorBankAccountEvent } from "../bank-accounts/schemas/bankAccount.schema";
import { ZContractorEvent } from "../contractors";
import { schemes } from "../schema-validation";
import { baseEventInput } from "./baseEvent";

export const contractorEventPayloadSchema = z.discriminatedUnion("category", [
  ZContractorEvent,
  ContractorBankAccountEvent,
]);

export const contractorEvent = baseEventInput.merge(
  z.object({
    source: z.literal("contractors"),
    contractorId: schemes.mongoObjectId(),
    companyId: schemes.uuid(),
    payload: contractorEventPayloadSchema,
  })
);

export type ContractorEvent = z.infer<typeof contractorEvent>;
