import { accountStatement, schemes, zSchema } from "@zeal/common";

export const getContractorPaycardStatementsRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  contractor_id: schemes.mongoObjectId(),
  date: schemes.isoDate().optional(),
  paycard_id: schemes.uuid(),
});

export type TGetContractorPaycardStatementsRequestSchema = zSchema.infer<
  typeof getContractorPaycardStatementsRequestSchema
>;

export const getContractorPaycardStatementsResponseSchema =
  zSchema.array(accountStatement);

export type TGetContractorPaycardStatementsResponseSchema = zSchema.infer<
  typeof getContractorPaycardStatementsResponseSchema
>;
