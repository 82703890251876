import { schemes, zSchema } from "@zeal/common";

export const getContractorPaycardStatementRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  contractor_id: schemes.mongoObjectId(),
  paycard_id: schemes.uuid(),
  statement_id: schemes.uuid(),
});

export type TGetContractorPaycardStatementRequestSchema = zSchema.infer<
  typeof getContractorPaycardStatementRequestSchema
>;

export const getContractorPaycardStatementResponseSchema = zSchema.object({
  url: zSchema.string(),
});

export type TGetContractorPaycardStatementResponseSchema = zSchema.infer<
  typeof getContractorPaycardStatementResponseSchema
>;
