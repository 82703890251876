import { z } from "zod";

export const quarterErrorMessage =
  "Invalid quarter value. Must be Q1, Q2, Q3, or Q4 (case-insensitive).";

export const quarter = z
  .string()
  .refine((value) => ["q1", "q2", "q3", "q4"].includes(value.toLowerCase()), {
    message: quarterErrorMessage,
  })
  .transform((value) => value.toUpperCase());
