import { z } from "zod";

type TParams = Parameters<typeof z.string>[0] & {
  readonly invalid_pattern_error?: string;
};

export const zipCode = (customErrors: TParams = {}) => {
  const { invalid_pattern_error = "Invalid Zip Code", ...zStringParams } =
    customErrors;
  return z
    .string(zStringParams)
    .trim()
    .regex(/^\d{5}(?:[-\s]\d{4})?$/, invalid_pattern_error)
    .transform((v) => v.slice(0, 5));
};
