import { ETxnSpeed } from "../ETxnSpeed";

export const getSpeedsForCompany = ({
  reserveEnabled,
  featureFlags,
}: {
  reserveEnabled: boolean;
  featureFlags: Record<string, boolean>;
}) => [
  {
    value: ETxnSpeed.TWO_DAY,
    label: "Two day",
  },
  ...(reserveEnabled
    ? [
        { value: ETxnSpeed.ONE_DAY, label: "One day" },
        { value: ETxnSpeed.ZERO_DAY, label: "Zero day" },
      ]
    : []),
  ...(featureFlags["enable-one-day-no-reserve-speed"]
    ? [{ value: ETxnSpeed.ONE_DAY_NO_RESERVE, label: "One day no reserve" }]
    : []),
  ...(featureFlags["enable-zero-day-no-reserve-speed"]
    ? [{ value: ETxnSpeed.ZERO_DAY_NO_RESERVE, label: "Zero day no reserve" }]
    : []),
  ...(featureFlags["enable-four-day-speed"]
    ? [{ value: ETxnSpeed.FOUR_DAY, label: "Four day" }]
    : []),
];
