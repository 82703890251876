import { z } from "zod";
import { Jurisdictions as supportedJurisdictions } from "../../jurisdictions";

export const jurisdiction = (errmsg?: string) =>
  z.string().transform((val, ctx) => {
    const jurisdiction = val.toUpperCase();

    if (
      supportedJurisdictions[
        jurisdiction as keyof typeof supportedJurisdictions
      ]
    ) {
      return jurisdiction;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: errmsg || "State does not represent a state in the US",
    });

    return z.NEVER;
  });
