import { z } from "zod";
import { bankAccountSchema } from "../bank-accounts/schemas/bankAccount.schema";
import { schemes } from "../schema-validation";
import { EWorkerInformationSection } from "./EWorkerInformationSection";

export const workerInformationSectionSchema = z.object({
  name: z.nativeEnum(EWorkerInformationSection),
});

export const shareableLinksTokenPayloadSchema = z.object({
  companyID: schemes.companyID(),
  email: schemes.email(),
  employeeID: schemes.mongoObjectId(),
  isShareableLink: z.literal(true),
  sections: z.array(workerInformationSectionSchema),
  testMode: z.boolean(),
  whiteLabelID: z.string(),
});

export const workerInformationCompanySchema = z.object({
  company_id: schemes.companyID(),
  business_name: z.string(),
});

export const workerInformationWorkerSchema = z.object({
  _id: schemes.mongoObjectId(),
  address: z.string().optional(),
  address_line2: z.string().optional(),
  city: z.string().optional(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  middle_initial: z.string().optional(),
  dob: z.string().optional(),
  phone_number: z.string().optional(),
  ssn: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  paycard_enabled: z.boolean().optional(),
});

export const workerInformationPayloadSchema = z.object({
  company: workerInformationCompanySchema,
  bankAccounts: z.array(bankAccountSchema).nullish(),
  logoURL: z.string().nullish(),
  worker: workerInformationWorkerSchema.optional(),
  tokenPayload: shareableLinksTokenPayloadSchema,
});

export type TShareableLinksSection = z.infer<
  typeof workerInformationSectionSchema
>;
export type TShareableLinksTokenPayload = z.infer<
  typeof shareableLinksTokenPayloadSchema
>;
export type TWorkerInformationPayload = z.infer<
  typeof workerInformationPayloadSchema
>;
