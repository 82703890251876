import { accountStatement, schemes, zSchema } from "@zeal/common";

export const getEmployeePaycardStatementsRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  date: schemes.isoDate().optional(),
  employee_id: schemes.mongoObjectId(),
  paycard_id: schemes.uuid(),
});

export type TGetEmployeePaycardStatementsRequestSchema = zSchema.infer<
  typeof getEmployeePaycardStatementsRequestSchema
>;

export const getEmployeePaycardStatementsResponseSchema =
  zSchema.array(accountStatement);

export type TGetEmployeePaycardStatementsResponseSchema = zSchema.infer<
  typeof getEmployeePaycardStatementsResponseSchema
>;
