import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateBankAccountRequestSchema,
  TCreateBankAccountResponseSchema,
} from "./createBankAccount.schema";

export const createBankAccount = (axios: IAxiosInstance) => {
  return async (body: TCreateBankAccountRequestSchema) => {
    try {
      const response = await axios.noRetry.post<
        IApiResponse<TCreateBankAccountResponseSchema>
      >(`/bankaccount`, body);

      const { data } = response.data;

      return data;
    } catch (error: any) {
      throw new Error(
        (error?.response.data.errors &&
          error.response.data.errors[0].message) ||
          "Error create bank account. Please contact support."
      );
    }
  };
};
