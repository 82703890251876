import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPaycardStatementsRequestSchema,
  TGetPaycardStatementsResponseSchema,
} from "./getPaycardStatements.schema";

export const getPaycardStatements = (axios: IAxiosInstance) => {
  return async (params: TGetPaycardStatementsRequestSchema) => {
    const { paycard_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPaycardStatementsResponseSchema>
    >(`/api/pay-cards/${paycard_id}/statements`, { params: rest });

    return response.data;
  };
};
