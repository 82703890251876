import { TWorkerInformationPayload } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const validateShareableLinkToken = (axios: IAxiosInstance) => {
  return async (body: { accessToken: string }) => {
    const response = await axios.noRetry.post<{
      data: TWorkerInformationPayload;
    }>("/api/validateShareableLinkToken", body);
    return response.data.data;
  };
};
