import dayjs from "dayjs";
import { buildDate } from "../buildDate/buildDate";

export const nthDayOf = (
  week: number,
  day: number,
  month: number,
  year: number
): dayjs.Dayjs => {
  let result = dayjs(buildDate({ month, year })).day(day);

  if (result.month() !== month - 1) {
    result = result.add(1, "week");
  }

  result = result.add(week - 1, "week");

  return result;
};
