import { EWorkerInformationSection, schemes } from "@zeal/common";
import { z } from "zod";

export const postGenerateShareableLinkSchema = z.object({
  companyID: schemes.companyID(),
  employeeID: schemes.mongoObjectId(),
  section: z.nativeEnum(EWorkerInformationSection),
  send_to_worker: z.boolean().optional(),
});

export type TPostGenerateShareableLinkRequest = z.infer<
  typeof postGenerateShareableLinkSchema
>;
