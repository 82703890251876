import {
  TGetContractorsGrossPayQuery,
  TGetContractorsGrossPayResponseData,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TestMode } from "../../@types/general";

export const getContractorsGrossPay = (axios: IAxiosInstance) => {
  return async ({
    mode,
    currentYear,
    status,
  }: TGetContractorsGrossPayQuery) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetContractorsGrossPayResponseData>
    >("/api/getContractorsGrossPay", {
      params: {
        test: mode === TestMode.Test,
        currentYear,
        status,
      },
      withCredentials: true,
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
