import dayjs from "dayjs";
import { buildDate } from "../buildDate/buildDate";

export const lastDayOfMonth = (
  day: number,
  month: number,
  year: number = new Date().getFullYear()
): dayjs.Dayjs => {
  const days_in_month = dayjs(buildDate({ month, year })).daysInMonth();
  const lastDayOfMonth = dayjs(
    `${year}-${month}-${days_in_month}`,
    "YYYY-MM-DD"
  );

  let result = lastDayOfMonth.day(day);

  if (result.month() !== month - 1) {
    result = result.subtract(1, "week");
  }

  return result;
};
