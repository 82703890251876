import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGetWorkerPaycardDisclosuresRequest,
  TGetWorkerPaycardDisclosuresResponse,
} from "./getEmployeePaycardDisclosures.schema";

export const getEmployeePaycardDisclosures = (axios: IAxiosInstance) => {
  return async ({
    workerId,
    document_keys,
  }: TGetWorkerPaycardDisclosuresRequest) => {
    const response = await axios.noRetry.get<
      ZealApiResponse<TGetWorkerPaycardDisclosuresResponse>
    >(`/api/employees/${workerId}/paycard-disclosures`, {
      params: {
        document_keys,
      },
    });

    if (response.data?.success) {
      return response.data.data;
    }

    throw new Error("Something went wrong.");
  };
};
