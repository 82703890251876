/**
 * List of resources that can be used in the permissions system.
 *
 * @description
 * - Though difficult in our current setup, we should aim to have resources be
 *   separated from roles (e.g. `ContractorPayments`, `AdminSettings`, etc.)
 * - Resources should span a granular feature that a user may access through the frontend/backed, frontend, or just backend.
 */
export const EPermissionResources = {
  CompanySettings: "company.settings",
  ContractorPayments: "contractor.payments",
  Contractors: "company.contractors",
  ContractorSettings: "contractor.settings",
  Deductions: "company.payroll.deductions",
  Employees: "company.employees",
  EmployeeChecks: "employee.checks",
  EmployeeSettings: "employee.settings",
  InternalTools: "internal.tools",
  Locations: "company.locations",
  Onboarding: "company.onboarding",
  PartnerAPI: "partner.api",
  PartnerMembership: "partner.membership",
  PartnerSettings: "partner.settings",
  PartnerCompanies: "partner.companies",
  Paycards: "paycards",
  PaycardStatements: "paycard.statements",
  Payroll: "company.payroll",
  Reports: "company.reports",
  Shifts: "company.payroll.shifts",
  Taxes: "company.taxes",
} as const;

export type EPermissionResources =
  (typeof EPermissionResources)[keyof typeof EPermissionResources];
