export const JobServices = {
  Compass1099Filings: "compass-1099-filings",
  Compass1099Approval: "compass-1099-approval",
  CompassStateFilings: "compass-state-filings",
  CompassCreditReductionReport: "compass-credit-reduction",
  CompassIrsForms: "compass-irs-forms",
  CompassMasterHeaders: "compass-master-headers",
  CompassPaLocalsReport: "compass-pa-locals-report",
  CompassReferenceFiles: "compass-reference-files",
  CompassW2Filings: "compass-w2-filings",
  CompassW2Approval: "compass-w2-approval",
  PreProcessChecks: "pre-process-checks",
  PreviewChecks: "preview-checks",
  PreviewCheckDate: "preview-check-date",
  ProcessChecks: "process-checks",
  PreviewChecksFLSA: "preview-checks-flsa",
  Reports: "reports",
  RePreProcessChecks: "re-pre-process-checks",
  ProcessBullhornCsv: "bullhorn-csv",
} as const;

export type JobServices = (typeof JobServices)[keyof typeof JobServices];
