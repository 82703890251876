import { z } from "zod";
import { EContractorPaymentStatus } from "../contractor-payments";

const getContractorsGrossPaySchema = z.object({
  mode: z.enum(["test", "live"]),
  currentYear: z.string().optional(),
  status: z.array(z.nativeEnum(EContractorPaymentStatus)),
});

export type TGetContractorsGrossPayQuery = z.infer<
  typeof getContractorsGrossPaySchema
>;

const getContractorsGrossPayResponseData = z.object({
  totalAmount: z.number(),
});

export type TGetContractorsGrossPayResponseData = z.infer<
  typeof getContractorsGrossPayResponseData
>;
