import { EEVerifyPublicCaseStatus } from "@zeal/common";
import { z } from "zod";

export const everifyWebhookSchema = z.object({
  test: z.boolean(),
  companyID: z.string(),
  business_name: z.string(),
  employeeID: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  everify_case_number: z.string(),
  everify_status: z.nativeEnum(EEVerifyPublicCaseStatus),
  everify_further_action_notice_url: z.string().optional(),
  everify_referral_date_confirmation_url: z.string().optional(),
});

export const everifyWebhookRequestSchema = z.object({
  url: z.string(),
  testMode: z.boolean(),
});

export type TEVerifyWebhookRequest = z.infer<
  typeof everifyWebhookRequestSchema
>;
export type TEVerifyWebhookBody = z.infer<typeof everifyWebhookSchema>;
