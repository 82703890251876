import { schemes, zSchema } from "@zeal/common";

export const getEmployeePaycardStatementRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  employee_id: schemes.mongoObjectId(),
  paycard_id: schemes.uuid(),
  statement_id: schemes.uuid(),
});

export type TGetEmployeePaycardStatementRequestSchema = zSchema.infer<
  typeof getEmployeePaycardStatementRequestSchema
>;

export const getEmployeePaycardStatementResponseSchema = zSchema.object({
  url: zSchema.string(),
});

export type TGetEmployeePaycardStatementResponseSchema = zSchema.infer<
  typeof getEmployeePaycardStatementResponseSchema
>;
