import { ETxnSpeed } from "../../transaction-speeds";
import {
  DisbursementMethodSpeedConfig,
  TransactionSpeedConfig,
} from "../../transaction-speeds/TransactionSpeedConfig";
import { EDisbursementMethod } from "../../workers-shared";
import dayjs from "../dayjs";

const TIMEZONE = "America/Los_Angeles";

export const validateDateForSpeedAndDisbursementMethod = ({
  date,
  disbursementMethod,
  speed,
}: {
  date: string;
  disbursementMethod: EDisbursementMethod;
  speed: ETxnSpeed;
}): "past" | "non-business-day" | "too-late" | undefined => {
  const checkDateObj = dayjs.tz(date, TIMEZONE).startOf("day");
  const now = dayjs().tz(TIMEZONE);

  if (checkDateObj.isBefore(now.startOf("day"))) {
    return "past";
  }

  if (!checkDateObj.isBusinessDay()) {
    return "non-business-day";
  }

  const disbursementCutoffHour =
    DisbursementMethodSpeedConfig[disbursementMethod].cutoffHour;
  const disbursementCutoffMinute =
    DisbursementMethodSpeedConfig[disbursementMethod].cutoffMinute;
  const disbursementDaysBeforeCheckDate =
    DisbursementMethodSpeedConfig[disbursementMethod].daysBeforeCheckDate;

  const speedCutoffHour = TransactionSpeedConfig[speed].cutoffHour;
  const speedCutoffMinute = TransactionSpeedConfig[speed].cutoffMinute;
  const speedDaysBeforeCheckDate =
    TransactionSpeedConfig[speed].daysBeforeCheckDate;

  const speedCutoff = checkDateObj
    .subtractBusinessDays(speedDaysBeforeCheckDate)
    .set("hour", speedCutoffHour)
    .set("minute", speedCutoffMinute);
  const speedDiff = speedCutoff.diff(now);

  const disbursementCutoff = checkDateObj
    .subtractBusinessDays(disbursementDaysBeforeCheckDate)
    .set("hour", disbursementCutoffHour)
    .set("minute", disbursementCutoffMinute);
  const disbursementDiff = disbursementCutoff.diff(now);

  if (speedDiff <= 0 || disbursementDiff <= 0) {
    return "too-late";
  }
};
