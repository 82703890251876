import {
  allKeysOptionalI9DocumentZodSchema,
  ECitizenshipStatus,
  EDocumentField,
  EDocumentKey,
  EDocumentList,
  EEVerifyDelayedCaseReasonCodes,
  EEVerifyDuplicateContinueReasonCodes,
  zSchema,
} from "@zeal/common";

export const postTriggerCaseOnOnboardingSchema = zSchema.object({
  company_id: zSchema.string(),
  worker: zSchema.object({
    date_of_birth: zSchema.string(),
    date_of_hire: zSchema.string(),
    first_name: zSchema.string(),
    last_name: zSchema.string(),
    ssn: zSchema.string().length(9),
    workerID: zSchema.string(),
  }),
  i9OnboardingInfo: zSchema.object({
    citizenship_info: zSchema.object({
      status: zSchema.nativeEnum(ECitizenshipStatus),
      alien_number: zSchema
        .string()
        .regex(
          /^A\d{8,9}$/,
          'Invalid alien number. Must be the letter "A" followed by 8 or 9 digits.'
        )
        .optional(),
      i94_number: zSchema
        .string()
        .regex(/^[0-9A-Za-z]{1,11}$/, "Invalid I94 number.")
        .optional(),
      i551_number: zSchema
        .string()
        .regex(/^[A-Za-z]{3}(\d{10}|[*]\d{9})$/, "Invalid I551 number.")
        .optional(),
      foreign_passport_number: zSchema
        .string()
        .regex(/^[A-Za-z0-9]{1,12}$/, "Invalid foreign passport number.")
        .optional(),
      alien_number_or_i94_number_or_foreign_passport_number: zSchema
        .enum(
          [
            EDocumentField.ALIEN_NUMBER,
            EDocumentField.I94_NUMBER,
            EDocumentField.FOREIGN_PASSPORT_NUMBER,
          ],
          {
            required_error:
              "Either alien number, I94 number, or foreign passport number is required.",
          }
        )
        .optional(),
      country_code: zSchema
        .string({
          required_error:
            "Please select the country that issued your foreign passport.",
        })
        .optional(),
      expiration_date: zSchema.string().optional(),
    }),
    documents: zSchema.array(
      zSchema.object({
        document_key: zSchema.nativeEnum(EDocumentKey),
        document_list: zSchema.nativeEnum(EDocumentList),
        document_data: allKeysOptionalI9DocumentZodSchema.optional(),
      })
    ),
  }),
  duplicate_continue_reason_code: zSchema
    .nativeEnum(EEVerifyDuplicateContinueReasonCodes)
    .optional(),
  duplicate_continue_reason: zSchema.string().optional(),
  reason_for_delay_code: zSchema
    .nativeEnum(EEVerifyDelayedCaseReasonCodes)
    .optional(),
  reason_for_delay_description: zSchema.string().optional(),
  testMode: zSchema.boolean(),
});

export type TPostTriggerCaseOnOnboardingSchema = zSchema.infer<
  typeof postTriggerCaseOnOnboardingSchema
>;
