import { schemes, zSchema } from "../schema-validation";

export const paginationSchema = zSchema.object({
  limit: zSchema.number().min(1).max(100).optional(),
  startAt: schemes
    .mongoOrUuid("A valid start_at pagination cursor is required")
    .optional(),
});

export type TPaginationSchema = zSchema.input<typeof paginationSchema>;
