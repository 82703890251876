import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

type Options = {
  isOnboarded?: boolean;
};

export const getContractors = (axios: IAxiosInstance) => {
  return async (options?: Options) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetMyContractorsResponse"]
    >(`/api/getMyContractors`, {
      params: { isOnboarded: options?.isOnboarded },
    });
    const data = response.data;

    return data;
  };
};
