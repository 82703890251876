import { z } from "zod";

export const ssn = (errmsg?: string) =>
  z
    .string()
    .trim()
    .refine((val) => {
      if (val.match(/-/g)?.length) {
        const ssnRegex = /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
        return ssnRegex.test(val);
      }
      const ssnRegex = /^(?!000|666|9\d{2})\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
      return ssnRegex.test(val);
    }, errmsg || "Invalid SSN")
    .transform((val) => val.replace(/-/g, ""));
