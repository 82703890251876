import { ssn } from "../schema-validation/schemes/ssn";

export const generateRandomSSN = () => {
  // Generate a valid SSN prefix (Area Number)
  let area = Math.floor(Math.random() * 900 + 1); // 001 to 899
  while (area === 666 || area >= 900) {
    area = Math.floor(Math.random() * 900 + 1); // Exclude 666 and 900+
  }

  // Generate a valid Group Number (00 is invalid)
  const group = Math.floor(Math.random() * 99 + 1); // 01 to 99

  // Generate a valid Serial Number (0000 is invalid)
  const serial = Math.floor(Math.random() * 9999 + 1); // 0001 to 9999

  // Format as "AAA-GG-SSSS"
  const str = `${String(area).padStart(3, "0")}-${String(group).padStart(
    2,
    "0"
  )}-${String(serial).padStart(4, "0")}`;

  return ssn().parse(str);
};
