import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGetContractorPaycardDisclosuresRequest,
  TGetContractorPaycardDisclosuresResponse,
} from "./getContractorPaycardDisclosures.schema";

export const getContractorPaycardDisclosures = (axios: IAxiosInstance) => {
  return async ({
    workerId,
    document_keys,
  }: TGetContractorPaycardDisclosuresRequest) => {
    const response = await axios.noRetry.get<
      ZealApiResponse<TGetContractorPaycardDisclosuresResponse>
    >(`/api/contractors/${workerId}/paycard-disclosures`, {
      params: {
        document_keys,
      },
    });

    if (response.data?.success) {
      return response.data.data;
    }

    throw new Error("Something went wrong.");
  };
};
