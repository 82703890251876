import { z } from "zod";

const base = z.object({
  companyID: z.string(),
  type: z.string(),
  description: z.string(),
  id: z.string(),
  link: z.string(),
  s3_key: z.string(),
  year: z.string(),
  quarter: z.string(),
});

export const taxDocumentSchema = z.object({
  company_id: z.string(),
  id: z.string().optional(),
  _id: z.string(),
  jurisdiction: z.string(),
  year: z.string(),
  quarter: z.string().optional(),
  type: z.enum([
    "940",
    "941",
    "943",
    "945",
    "1099MISC",
    "1099",
    "1099 - Corrected",
    "W2",
    "W2-C",
    "Unemployment",
    "Witholding",
    "Other",
  ]),
  filing_due_date: z.string(),
  filing_status: z.enum([
    "Pending",
    "Filed",
    "Filed Late",
    "Missing",
    "Not Applicable",
  ]),
  other_reason: z.string().optional(),
  description: z.string().optional(),
  owner_type: z.enum(["employee", "employer"]),
  s3_key: z.string(),
  visible_in_api: z.boolean().default(true),
});

export type TaxDocument = z.infer<typeof taxDocumentSchema>;
export type GetTaxDocumentsResponse = {
  documents: (TaxDocument & {
    s3_key?: string;
    employee_name?: string;
    employeeID?: string;
  })[];
  bulkEmployeeLink: string;
  company: {
    readonly company_id: string;
    readonly white_label_id: string;
    readonly email: string;
    readonly first_name: string;
    readonly last_name: string;
    readonly business_name: string;
    readonly business_ein: string;
    readonly odo_user_id?: string;
    readonly status: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly partner_name: string;
  };
  previousCursor: string;
  nextCursor: string;
};

const TaxDocumentUrlSchema = base.omit({ link: true });
const TaxDocumentUrlResponseSchema = base.omit({ s3_key: true });

export type TaxDocumentUrl = z.infer<typeof TaxDocumentUrlSchema>;
export type TaxDocumentUrlResponse = z.infer<
  typeof TaxDocumentUrlResponseSchema
>;

export const updateTaxDocumentSchema = taxDocumentSchema.pick({
  filing_due_date: true,
  filing_status: true,
  jurisdiction: true,
  quarter: true,
  type: true,
  year: true,
  other_reason: true,
});

export const createTaxDocumentSchema = taxDocumentSchema.pick({
  filing_due_date: true,
  filing_status: true,
  jurisdiction: true,
  quarter: true,
  type: true,
  year: true,
  other_reason: true,
});

export type UpdateTaxDocumentParams = z.infer<typeof updateTaxDocumentSchema>;
export type CreateTaxDocumentParams = z.infer<typeof createTaxDocumentSchema>;
