import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPaycardStatementRequestSchema,
  TGetPaycardStatementResponseSchema,
} from "./getPaycardStatement.schema";

export const getPaycardStatement = (axios: IAxiosInstance) => {
  return async (params: TGetPaycardStatementRequestSchema) => {
    const { paycard_id, statement_id } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPaycardStatementResponseSchema>
    >(`/api/pay-cards/${paycard_id}/statements/${statement_id}`);

    return response.data;
  };
};
