import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetEmployeePaycardStatementsRequestSchema,
  TGetEmployeePaycardStatementsResponseSchema,
} from "./getEmployeePaycardStatements.schema";

export const getEmployeePaycardStatements = (axios: IAxiosInstance) => {
  return async (params: TGetEmployeePaycardStatementsRequestSchema) => {
    const { employee_id, paycard_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetEmployeePaycardStatementsResponseSchema>
    >(`/api/employees/${employee_id}/paycards/${paycard_id}/statements`, {
      params: rest,
    });

    return response.data;
  };
};
