import { ZealApiResponse, TCreateVeriffSessionRequestBody } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export interface ICreateVeriffSessionResponse {
  status: "success";
  verification: {
    id: string; // String UUID v4 which identifies the verification session
    url: string; // URL of the verification to which the person is redirected
    vendorData: string | null; // End-user-specific data string, max 1000 characters long, created by the customer.
    host: string; // The base url the sessionToken can be used for
    status: string; // Verification session status
    sessionToken: string; // Session-specific token of the verification
  };
}

export const createVeriffSession = (axios: IAxiosInstance) => {
  return async (requestBody: TCreateVeriffSessionRequestBody) => {
    const accessToken = requestBody.accessToken;
    const response = await axios.noRetry.post<
      ZealApiResponse<ICreateVeriffSessionResponse>
    >(`/api/createVeriffSession`, { ...requestBody, accessToken });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
