import { z } from "zod";
import { EVeriffDocumentType } from "./EVeriffDocumentType";

export const createVeriffSessionRequestBodySchema = z.object({
  useTestKey: z.boolean().default(true).optional(),
  accessToken: z.string(),
  verification: z.object({
    person: z.object({
      firstName: z.string(),
      lastName: z.string(),
      idNumber: z.string().optional(), // National identification number
      gender: z.string().optional(),
      dateOfBirth: z.string().optional(),
    }),
    document: z.object({
      number: z.string().optional(), // Document number, [a-zA-Z0-9] characters only
      type: z.nativeEnum(EVeriffDocumentType),
      country: z.string().optional(), // ISO-2 String Issuing country of the document
    }),
    lang: z.string().optional(),
    timestamp: z.string().optional(),
  }),
});

export type TCreateVeriffSessionRequestBody = z.infer<
  typeof createVeriffSessionRequestBodySchema
>;
