import { z } from "zod";

export const yearErrorMessage = "Year must be between 1900 and 2200";

export const year = z
  .string()
  .regex(/^\d{4}$/, "Year must be a 4-digit number")
  .refine((year) => {
    const numYear = Number(year);
    return numYear >= 1900 && numYear <= 2200;
  }, yearErrorMessage);
