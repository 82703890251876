import { z } from "zod";

const uuidRegex = z.string().regex(/^[0-9a-f]{32}$/);
const mongoIdRegex = z.string().regex(/^[0-9a-f]{24}$/);

export const mongoOrUuid = (errorMsg?: string) =>
  z
    .string()
    .refine(
      (id) =>
        mongoIdRegex.safeParse(id).success || uuidRegex.safeParse(id).success,
      {
        message: errorMsg || "Invalid ID",
      }
    );
