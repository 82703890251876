import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetTransactionRecordRequestSchema,
  TGetTransactionRecordResponseSchema,
} from "./getTransactionRecord.schema";

export const getTransactionRecord = (axios: IAxiosInstance) => {
  return async (query: TGetTransactionRecordRequestSchema) => {
    const { transaction_record_id } = query;

    const response = await axios.noRetry.get<
      IApiResponse<TGetTransactionRecordResponseSchema>
    >(`/api/transactions/${transaction_record_id}`);

    return response.data;
  };
};
