import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetEmployeeChecksRequestSchema,
  TGetEmployeeChecksResponseSchema,
} from "./getEmployeeChecks.schema";

export const getEmployeeChecks = (axios: IAxiosInstance) => {
  return async (body: TGetEmployeeChecksRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TGetEmployeeChecksResponseSchema>
    >(`/api/employee-checks`, body);

    return response.data;
  };
};
