import { z } from "zod";
import { EPaymentCreationType } from "../payment-creation-types/EPaymentCreationType";
import { schemes } from "../schema-validation";
import { ETxnSpeed } from "../transaction-speeds";
import { EDisbursementMethod } from "../workers-shared";

import { baseEventInput } from "./baseEvent";

export const transactionEvent = baseEventInput.merge(
  z.object({
    source: z.literal("odo.webhooks.transaction"),
    payload: z
      .object({
        amount: z.number(),
        disbursementMethod: z.nativeEnum(EDisbursementMethod),
        id: z.string(),
        returnCode: z.string().optional(),
        speed: z.nativeEnum(ETxnSpeed),
        status: z.string(),
        toDestinationId: schemes.uuid().optional(),
        toOdoId: z.string(),
        transactionEventId: schemes.uuid(),
        type: z.nativeEnum(EPaymentCreationType),
        zealPaymentId: z.string(),
      })
      .passthrough(),
  })
);

export type TransactionEvent = z.infer<typeof transactionEvent>;
