import { schemes, zSchema } from "@zeal/common";

export const getPaymentDestinationsRequestSchema = zSchema.object({
  odo_user_ids: zSchema.array(schemes.mongoOrUuid()).optional(),
});

export type TGetPaymentDestinationsRequestSchema = zSchema.infer<
  typeof getPaymentDestinationsRequestSchema
>;

export const getPaymentDestinationsResponseSchema = zSchema.array(
  zSchema.object({
    odo_user_id: schemes.mongoOrUuid(),
    payment_destination_id: schemes.uuid(),
  })
);

export type TGetPaymentDestinationsResponseSchema = zSchema.infer<
  typeof getPaymentDestinationsResponseSchema
>;
