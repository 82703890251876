import { z } from "zod";
import { productDefinitionSchema } from "../../products/schemas/productDefinitionSchema";
import { schemes } from "../../schema-validation";
import { uuid } from "../../schema-validation/schemes";
import { taskDefinitionTemplateSchema } from "./taskDefinitionTemplateSchema";

export const taskDefinitionSchema = z.object({
  taskDefinitionId: uuid(),
  productDefinitionId: uuid(),
  taskDefinitionTemplateId: uuid(),
  companyID: uuid(),
  enabled: z.boolean().default(false),
  default: z.boolean().default(false),
  categoryEnablements: z
    .map(z.enum(["userAccount", "bankAccount"]), z.boolean())
    .default(
      new Map([
        ["userAccount", true],
        ["bankAccount", true],
      ])
    ),
});

export const taskDefinitionTaskSchema = taskDefinitionSchema.transform(
  (data) => {
    const { enabled, ...d } = data;
    return {
      ...d,
      fields: {
        default: d.default,
        enabled,
      },
    };
  }
);

export const taskDefinitionRefsPopulatedSchema = taskDefinitionSchema.extend({
  taskDefinition: taskDefinitionTemplateSchema.pick({
    requirements: true,
  }),
  productDefinition: productDefinitionSchema.pick({
    product: true,
    description: true,
    productType: true,
  }),
  inProgressTasks: z.number().default(0),
  completeTasks: z.number().default(0),
});

export type ZTaskDefinition = z.infer<typeof taskDefinitionSchema>;
export type ZTaskDefinitionRefsPopulated = z.infer<
  typeof taskDefinitionRefsPopulatedSchema
>;
export type ZTaskDefinitionTask = z.infer<typeof taskDefinitionTaskSchema>;

const productEnums =
  taskDefinitionRefsPopulatedSchema.shape.productDefinition.shape.product;
const taskDefinitionEnabled = taskDefinitionRefsPopulatedSchema.shape.enabled;
const defaultTaskDefinition =
  taskDefinitionRefsPopulatedSchema.shape.default.nullish();

const categoryEnablements = z
  .record(z.enum(["userAccount", "bankAccount"]), z.boolean())
  .transform((obj) => new Map(Object.entries(obj)));

export const updateTaskDefinitionSchema = z.object({
  default: defaultTaskDefinition,
  product: productEnums,
  enabled: taskDefinitionEnabled,
  categoryEnablements: categoryEnablements.optional(),
});

export const updateTaskDefinitionsSchema = z.object({
  companyID: schemes.uuid(),
  taskDefinitions: z.array(updateTaskDefinitionSchema),
});

export type TUpdateCompanyTaskDefinitionsBody = z.infer<
  typeof updateTaskDefinitionsSchema
>;

export type TUpdateTaskDefinitionBody = z.infer<
  typeof updateTaskDefinitionSchema
>;
