import { ZealApiResponse } from "@zeal/common";
import { TUpdateCompanyTaskDefinitionsBody } from "@zeal/common/dist/tasks/schemas/taskDefinitionSchema";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const updateCompanyTaskDefinitions = (axios: IAxiosInstance) => {
  return async (data: TUpdateCompanyTaskDefinitionsBody) => {
    const response = await axios.noRetry.patch<
      ZealApiResponse<TUpdateCompanyTaskDefinitionsBody>
    >(`/company/${data.companyID}/tasks/definitions`, { ...data });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
