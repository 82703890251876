import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetContractorPaycardStatementRequestSchema,
  TGetContractorPaycardStatementResponseSchema,
} from "./getContractorPaycardStatement.schema";

export const getContractorPaycardStatement = (axios: IAxiosInstance) => {
  return async (params: TGetContractorPaycardStatementRequestSchema) => {
    const { contractor_id, paycard_id, statement_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetContractorPaycardStatementResponseSchema>
    >(
      `/api/contractors/${contractor_id}/paycards/${paycard_id}/statements/${statement_id}`,
      { params: rest }
    );

    return response.data;
  };
};
